/**
 * Dict welches die wichtigsten String auf der Webseite zum Anzeigen setzt
 *
 * @author DHR
 */
const data = {
    roles: {
        ADMIN: 'ADMIN',
        BECKOFFICE: 'BECKOFFICE',
        QUALITAETSSICHERUNG: 'QUALITAETSSICHERUNG',
        MITARBEITER: 'MITARBEITER',
    },
    status: {
        BEARBEITUNG: 'BEARBEITUNG',
        FREIGABE_MITARBEITER: 'FREIGABE_MITARBEITER',
        FREIGABE_QUALITAETSSICHERUNG: 'FREIGABE_QUALITAETSSICHERUNG', // === Veröffentlicht
        GESPERRT: 'GESPERRT',
    },
    presentation: {
        roles: {
            ADMIN: 'Admin',
            BECKOFFICE: 'beckOffice',
            QUALITAETSSICHERUNG: 'Qualitätssicherung',
            MITARBEITER: 'Mitarbeiter',
        },
        status: {
            BEARBEITUNG: 'in Bearbeitung',
            FREIGABE_MITARBEITER: 'Freigabe notwendig', // === Freigabe Mitarbeiter
            FREIGABE_QUALITAETSSICHERUNG: 'Freigegeben', // === Veröffentlicht
            GESPERRT: 'Gesperrt',
        },
    },
    accessStatus: {
        search: 'search',
        create: 'create',
        update: 'update',
        readOnly: 'readOnly',
        custom: 'custom',
    },
    businessObject: {
        user: 'benutzer',
        consultant: 'berater',
        consultantproject: 'beraterprojekt',
        customer: 'kunde',
        customerproject: 'kundenprojekt',
        competence: 'kompetenz',
        gefuehrt: 'gefuehrt',
        moderation: 'moderation',
        drucken: 'drucken',
        layout: 'layout',
        qualification: 'qualifikation',
        contactperson: 'ansprechpartner',
    },
    prefixes: {
        mandant: 'M_',
        competence: 'KZ_',
        qualification: 'Q_',
        user: 'U_',
        customer: 'K_',
        consultant: 'B_',
        customerproject: 'KP_',
        consultantproject: 'BP_',
        contactperson: 'AP_',
    },
    projectStatus: {
        isVorlage: 'Vorlage',
        isReferenz: 'Referenz',
        druck: 'Druck',
        isKomplett: 'Vollständig',
    },
    inputTypes: {
        standard: {
            DATE: 'date',
            PLAIN: 'plain',
            SHORT: 'short',
            TEXTAREA: 'textarea',
            CHECKBOX: 'checkbox',
            NUMBER: 'number',
            TEXT: 'text',
            MULTIPLE: 'multiple',
            PASSWORD: 'password',
        },
        advanced: {
            FILE: 'file',
            DROPDOWN: 'dropdown',
            INLINEDEFINE: 'inLineDefine',
            ROWDEFINE: 'rowDefine',
            OBJECTFK: 'ObjectFK',
            TABLE: 'table',
            SINGLESELECT: 'singleSelect',
            MULTISELECT: 'multiSelect',
        },
        NONE: 'none',
    },
    logoutTimer: {regular: 30 * 60 * 1000, goodwill: 2 * 60 * 1000},
    version: '1.50',
    DEBUG: true,
}

//Kompetenztyp, Niveau, Referenztyp, Qualifikationstyp, Projekttyp, Projektstandort, Dateityp, Datieformat, Branche, Abteilung, Bankart, Anrede, Titel, Adresstyp, Adressat, Sicht -> einmaliger Abruf zu Webanwendungsaufruf?

export default data
