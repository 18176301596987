import React, {useState, useEffect} from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router'

import businessobject_data from '../../../data/businessobject_data'
import data from '../../../data/_data'

import PflegenKopfzeile_Beraterprojekt from './PflegenKopfzeile_Beraterprojekt'
import PflegenFusszeile from '../../Fusszeile'

import {sendGetConsultantprojectRequest, sendDeleteConsultantprojectRequest, sendCreateConsultantprojectRequest, sendUpdateConsultantprojectRequest, sendCreateFileRequest, sendDeleteFileRequest as sendDeleteConsultantprojectFileRequest, sendFileDownload} from '../../../redux/consultantprojects'
import {sendCreatePMCompetenceRequest} from '../../../redux/competences'
import NotFound from './../../NotFound';
import { list2options, object2options, objectList2options, optionsFilter, defaultEncoder, equals, flattenObject2Array, optionsDataByTypeInit, getOptions, somethingChanges, getOptionsInit, objectList2AttrList, validateValues, checkInvalidation, checkReadOnly, arrayDefaultFilter, fieldData2options, completeCompetenceAfterUpdate } from './../../_helpers/functions';
import {DropdownInput, TextInput, InputByType, FileInput, InLineInput, TableInput, RowInput, ObjectFKInput, HandleMainInputs} from '../../_helpers/InnerContent';

import { setSuccess } from './../../../redux/businessobjects';
import message_data from './../../../data/message_data';
import { PermissionForbidden } from './../../_helpers/ReadPermission';
import PopUp from './../../_helpers/PopUp';



/**
 * Unter-Komponente für die Seiten "Beraterprofil Pflegen".
 * Bearbeitet oder legt den gewählte Berater an
 * 
 * @author DHR
 * @param {String} role - aktuelle Rolle des angemeldeten Benutzers
 * @param {String} username - benutzername des angemeldeten Benutzers
 * @param {String} readOnly - gibt an, ob das Element schreibgeschützt ist
 * @param {String} accessStatus - status des Pflegens des Geschaeftsobjektes (suche, neu, gefuehrt)
 * @param {Object} id - die ID des konkreten Beraters
 * @param {Object} createdId - die ID des gerade erstellten Beraters
 * @param {Object} list - Liste aller Berater aus dem State, von der API
 * @param {Object} userList - Liste aller Benutzer aus dem State, von der API
 * @param {Function} get - Funktion zum request des spezifischen Beraters von der API
 * @param {Function} sendCreate - Funktion zum API call zum Erstellen des Objektes
 * @param {Function} sendUpdate - Funktion zum API call zum Aktualisieren des Objektes
 * @param {Function} sendDelete - Funktion zum API call zum Löschen des Objektes
 * @param {Function} sendCompetenceCreateRequest - Funktion zum API call zum Erstellen einer Anfrage für eine Kompetenz
 * @param {Function} sendCreateFile - Funktion zum API call zum Erstellen einer Datei
 * @param {Function} sendUploadProfilePic - Funktion zum API call zum Hochladen eines Profilbildes
 * @param {Function} sendDeleteFile - Funktion zum API call zum zum Löschen einer Datei
 * @param {Function} sendFileDownload - Funktion zum API call zum Downloaden einer Datei
 * @param {Object} requestedFile4Download - Die Angefragte Datei
 * @param {String} errorMsg - errorMsg wenn API call fehlschlägt
 * @param {String} fieldData - Feldauspraegungen aus dem State
 * @param {String} competenceData - Kompetenzen zur Auswahl im Dropdown o.ä.
 * @param {String} fieldDataError - errorMsg wenn API call, speziell für die Feldauspraegungen fehlschlägt
 * @param {String} onSelect - Funktion welche aufgerufen wird wenn Angeleggt wird, nur wnen Komponentnet direkt und nicht über das Routing aufgerufen wird
 * @param {String} onSelectProperty - Key des als Fremdschlüssel zu verwendenen Objektes bei onSelect
 * @param {String} successMsg - successMsg wenn Anlegen oder Update erfolgreich
 * @param {String} pmCompetenceSuccess - successMsg für die Anfrage zum Erstellen einer Kompetenz
 * @param {String} setfurtherSuccess - successMsg wenn auch das Update oder Anlegen von Dateien erfolgreich
 * @returns {HTML} Seite zum Pflegen der Geschaeftsobjekte
 * @see PflegenKopfzeile
 * @see PflegenFusszeile
 */
function Pflegen_Beraterprojekt({role, username, createdId, id, errorMsg, readOnly, accessStatus, object, list, fkLists, get, sendCreate, sendUpdate, sendDelete, sendCompetenceCreateRequest, sendCreateFile, sendDeleteFile, sendFileDownload, requestedFile4Download, fieldData, competenceData, fieldDataError, onSelect, onSelectProperty, successMsg, setfurtherSuccess, pmCompetenceSuccess}){
    // some State Var for PopUp and Notification about the success of an api call
    const [showDeletePopUp, setShowDeletePopUp] = useState(false)
    const [showLockPopUp, setShowLockPopUp] = useState(false)
    const [failedValidation, setFailedValidation] = useState({notnull: {}, regex: {}, semantic:  {}})
    const [tabOfFailure, setTabOfFailure] = useState('')
    const [wantedStatus, setWantedStatus] = useState(null)


    id = id.toUpperCase()
    const businessObject = data.businessObject.consultantproject
    
    const [tab, setTabintern] = useState(Object.keys(businessobject_data.beraterprojekt.fields)[0])
    const setTab = (tab2set) => {
        if(somethingChanges(accessStatus, values, object, businessObject) && !validateValues(setError, values, failedValidation, setFailedValidation, businessObject, setTabOfFailure)){
            if (tabOfFailure){
                window.scrollTo({ top: 0, behavior: 'smooth' })
                setTabintern(tabOfFailure)
                return;
            } else {
                return;
            }
        }
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setTabintern(tab2set)
    }  
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [])

    useEffect(()=> {if (tabOfFailure) {window.scrollTo({ top: 0, behavior: 'smooth' }); setTabintern(tabOfFailure)}}, [tabOfFailure])

    const history = useHistory();
    useEffect(() => {if (successMsg === message_data.success.redux.default){history.push('/pflegen')}}, [successMsg])
    // request api Data if needed
    useEffect(() => {
        if(accessStatus !== data.accessStatus.create){get(id)};
    }, [id])

    const getValues = () => {
        if (object && accessStatus === data.accessStatus.update){
            // look for the object in other status
            if(Array.isArray(object)){
                let tempObject = null
                if (role === data.roles.BECKOFFICE || role === data.roles.QUALITAETSSICHERUNG) {tempObject = object.find(b => (b.id === id && b.status !== data.status.BEARBEITUNG))}
                if(role === data.roles.MITARBEITER || object[0].berater == username){
                    tempObject = object.find(b => (b.status === data.status.BEARBEITUNG || b.status === data.status.FREIGABE_MITARBEITER) && b.berater == username)
                } 
                if (!tempObject){tempObject = object.find(b => (b.status === data.status.FREIGABE_QUALITAETSSICHERUNG))}
                if (wantedStatus){
                    let wantedObject = object.find((o) => o.status === wantedStatus)
                    if (wantedObject){
                        if (wantedObject.status !== tempObject.status){
                            readOnly = true
                        }
                        return defaultEncoder(wantedObject)
                    }
                }
                return defaultEncoder(tempObject)
            }
            return defaultEncoder(object)
        } else if (accessStatus === data.accessStatus.create){
            if (role === data.roles.MITARBEITER){
                return {...defaultEncoder(businessobject_data.beraterprojekt.default), berater: username}
            }
            return defaultEncoder(businessobject_data.beraterprojekt.default)
        } else {return {}}
    }

    const [error, setError] = useState("")
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [error, errorMsg])

    const [values, setValues] = useState(getValues())
    if (values){readOnly=(readOnly ? readOnly : PermissionForbidden(role, username, id, data.businessObject.consultantproject, values))}
    // load object
    useEffect(() => {
        setValues(getValues())
    }, [object, id, wantedStatus])

    
    // open PopUpto to Confirm on Function call
    const onDelete = () => {setShowDeletePopUp(true)}
    const onLock = () => {setShowLockPopUp(true)}
    // close PopUp and do api call
    const onDelete_sure = () => {setShowDeletePopUp(false);sendDelete(id);}
    const onLock_sure = () => {
        setShowLockPopUp(false)
        values.status = (object.status === data.status.GESPERRT ? data.status.FREIGABE_QUALITAETSSICHERUNG : data.status.GESPERRT)
        onSave()
    }
    // set status for Release according to userrole
    const onRelease = () => {
        values.status = (role === data.roles.MITARBEITER ? data.status.FREIGABE_MITARBEITER : data.status.FREIGABE_QUALITAETSSICHERUNG)
        onSave()
    }

    const onSavePM = () => {
        values.status = data.status.FREIGABE_MITARBEITER
        onSave()
    }

    const onSavePMPrivate = () => {
        values.status = data.status.BEARBEITUNG
        onSave()
    }

    const checkUnique = (symbol) => {
        if (symbol !== object?.symbol) {
            if (list?.find(o => o.symbol === symbol)){
                setError(message_data.error.pflegen.unique)
                return false
            }
        }
        return true
    }
    // validate and copy the object to save it via API call
    const onSave = () => {
        if (!validateValues(setError, values, failedValidation, setFailedValidation, businessObject, setTabOfFailure)){
            if (tabOfFailure){
                setTabintern(tabOfFailure)
                return;
            } else {
                return;
            }
        }
        
        if (values.status === data.status.FREIGABE_QUALITAETSSICHERUNG && values.kompetenzen){
            for (let k of values.kompetenzen){
                if (k.status !== data.status.FREIGABE_QUALITAETSSICHERUNG){
                    setError(message_data.error.pflegen.notreleased)
                    return;
                }
            }
        }

        if(!checkUnique(values.symbol)){return;}
        let tempObject = getValues()
        let files2delete = []
        if (tempObject.dateien){
            for (let file of tempObject.dateien){
                if (!values.dateien.find(f => f.id === file.id)){
                    files2delete.push(file.id)
                }
            }
        }
        for (let key of Object.keys(values)){
            if (!!values[key] || (!!tempObject[key] && !values[key])){
                if (Array.isArray(values[key])){
                    //filter default values out of the array
                    let tempValues = arrayDefaultFilter(values[key], key, businessObject)
                    if (tempValues.length > 0 || (tempObject[key] && tempObject[key].length > 0)){
                        tempObject[key] =  tempValues
                    }
                } else {
                    tempObject[key] = values[key]
                }
            }
        }
        sendUpdate(tempObject)

        for (let id of files2delete){
            sendDeleteFile(tempObject.id, id);
        }
    }

    const onCreatePM = () => {
        values.status = data.status.FREIGABE_MITARBEITER
        onCreate()
    }

    const onCreatePMPrivate = () => {
        values.status = data.status.BEARBEITUNG
        onCreate()
    }
    // lock the Object and create it
    const onCreateLock = () => {
        values.status = data.status.GESPERRT
        onCreate()
    }
    // validate and create the object via API call
    const onCreate = () => {
        if (!validateValues(setError, values, failedValidation, setFailedValidation, businessObject, setTabOfFailure)){
            if (tabOfFailure){
                setTabintern(tabOfFailure)
                return;
            } else {
                return;
            }
        }
        if (values.status === data.status.FREIGABE_QUALITAETSSICHERUNG && values.kompetenzen){
            for (let k of values.kompetenzen){
                if (k.status !== data.status.FREIGABE_QUALITAETSSICHERUNG){
                    setError(message_data.error.pflegen.notreleased)
                    return;
                }
            }
        }
        if(!checkUnique(values.symbol)){return;}
        object = {}
        for (let key of Object.keys(values)){
            if (key !== 'dateien' && ((!!values[key] && !equals(values[key], businessobject_data.berater.default[key])) || (businessobject_data.types[key] && ((typeof businessobject_data.types[key].notnull === 'boolean' && businessobject_data.types[key].notnull) || (Array.isArray(businessobject_data.types[key].notnull) && businessobject_data.types[key].notnull.includes(businessObject)))))){
                
                if (Array.isArray(values[key])){
                    //filter default values out of the array
                    let tempValues = arrayDefaultFilter(values[key], key, businessObject)
                    if (tempValues.length > 0){
                        object[key] =  tempValues
                    }
                } else {
                    object[key] = values[key]
                }
            }
        }
        sendCreate(object)

        if(onSelect){
            if (onSelectProperty){
                onSelect(object[onSelectProperty])
            } else {onSelect(object)}
        }
    }

    // after creating or updating the object, save files and images via API call
    useEffect(() => {
        if (createdId){
            let furtherUploads = false;
            if (!equals(values.dateien,businessobject_data.berater.default.dateien) && values.dateien){
                for (let file of values.dateien){
                    if(!equals(file, businessobject_data.datei.default) && !file.id && file.dateiData){
                        furtherUploads = true;
                        sendCreateFile(createdId, file)
                    }
                }
            }
            
            if (!furtherUploads) {
                setfurtherSuccess();
            }
        }
    },[createdId])

    // download a file
    const onFileDownload = (fileId) => {
        if(accessStatus===data.accessStatus.update && fileId){
            sendFileDownload(object.id, fileId)
        }
    }
    // send request to create a competence
    const onCompetenceCreateReq = (competence) => {
        sendCompetenceCreateRequest({...competence, status: data.status.FREIGABE_MITARBEITER, druck: false})
    }
    // set the competence or qualification to the object, if created successfully
    useEffect(() => {if (values && pmCompetenceSuccess){setValues({...values, kompetenzen:[...values['kompetenzen'], pmCompetenceSuccess]})}}, [pmCompetenceSuccess])

    // load functions to get keys and options data
    const optionsDataByType = optionsDataByTypeInit(competenceData, fieldData2options(fieldData))
    const getOptions = getOptionsInit(optionsDataByType)

    var tabs
    var fields
    tabs = Object.keys(businessobject_data.beraterprojekt.fields)
    fields = businessobject_data.beraterprojekt.fields[tab]

    // return inputfield for each key by type
    //load options for the input if needed
    function returnInput(type, index, key, presentation){
        if (type){
            switch(type.type){
                case data.inputTypes.advanced.DROPDOWN:
                    let optionsDrop = optionsDataByType(key)
                    return <DropdownInput key={index} multiSelect={!!type.multiSelect} value={values[key]} presentation={presentation} name={key} readOnly={checkReadOnly(key, readOnly, accessStatus)} setValue={v => setValues({...values, [key]: v})} options={optionsDrop} searchable={(type.searchable ? true : false)} notNull={((typeof type.notnull === 'boolean' && type.notnull) || (Array.isArray(type.notnull) && type.notnull.includes(businessObject)))} />
                case data.inputTypes.advanced.FILE:
                    return <FileInput key={index} picture={!!type.picture} requestedFile={requestedFile4Download} downloadFile={onFileDownload} multiple={!!type.multiSelect} dateitypOptions={optionsDataByType('dateityp')} presentation={presentation} field={key} readOnly={checkReadOnly(key, readOnly, accessStatus)} value={values[key]} setValue={v => setValues({...values, [key]: v})}  />
                case data.inputTypes.advanced.INLINEDEFINE:
                    let optionsObjInline = getOptions(key)
                    return <InLineInput key={index} name={key} presentation={presentation} create={!!type.create} readOnly={checkReadOnly(key, readOnly, accessStatus)} setValue={v => setValues({...values, [key]: v})} value={values[key]} defaultObject={{...businessobject_data[(businessobject_data.types[key].dataAttribute ? businessobject_data.types[key].dataAttribute : key)].default}} presentationObject={businessobject_data[(businessobject_data.types[key].dataAttribute ? businessobject_data.types[key].dataAttribute : key)].fields} optionsObject={optionsObjInline} invalidObject={checkInvalidation(key, failedValidation, setFailedValidation, values[key])} />
                case data.inputTypes.advanced.TABLE:
                    let optionsObjTable = getOptions(key)
                    let dropdownOptions = optionsDataByType(key)
                    if (key === 'kompetenzen'){
                        completeCompetenceAfterUpdate((v) => setValues({...values, [key]: v}), values[key], competenceData, type.dataAttribute, ['titel', 'kategorie', 'status'], type.idProperty)
                        return (
                            <div className='inner-contents' key={index}>
                                {(fieldData?Object.keys(fieldData2options(fieldData)['kompetenzkategorie']):(type&&type.data?Object.keys(type.data):[])).map((k, i) => {
                                    let tempDisabled = {kategorie: true, titel: true}
                                    let presentationDisabled = {druck: true, titel: true, niveau: true}
                                    let filteredValues = ((values && values[key]) ? values[key].filter(c => c.kategorie === k) : [])
                                    for (let i in filteredValues){
                                        if (filteredValues[i].status !== data.status.FREIGABE_QUALITAETSSICHERUNG){
                                            tempDisabled = {...tempDisabled, [i]: presentationDisabled}
                                        }
                                    }
                                    return <TableInput key={index*100+i} presentation={(fieldData?fieldData2options(fieldData)['kompetenzkategorie']:type.data)[k]} name={(key+(fieldData?fieldData2options(fieldData)['kompetenzkategorie']:type.data)[k])} readOnly={checkReadOnly(key, readOnly, accessStatus)} disabledObject={tempDisabled} presentationObject={businessobject_data.kompetenz.zoFields} value={filteredValues} options={optionsDataByType(key, k)} optionsObject={optionsObjTable} setValue={l => {let c = ((values && values[key]) ? values[key] : []).filter(c => c.kategorie !== k); c.push(...l); setValues({...values, [key]: c})}} onCreate={(role === data.roles.MITARBEITER ? (v) => (onCompetenceCreateReq({kategorie: k, titel: v})): null)} valueProperty={type.idProperty ? type.idProperty : key}  />
                                })}
                            </div>
                        )
                    } else if(key === "beraterrollen"){
                        completeCompetenceAfterUpdate((v) => setValues({...values, [key]: v}), values[key], competenceData, type.dataAttribute, ['titel', 'name'], type.idProperty)
                        return(
                            <div className='inner-contents' key={index}>
                                <TableInput key={index} presentation={presentation} name={key} readOnly={checkReadOnly(key, readOnly, data.accessStatus.update)} presentationObject={businessobject_data.beraterrolle.zoFields} disabledObject={{titel: true}} value={values[key]} setValue={v => setValues({...values, [key]: v})} options={dropdownOptions} optionsObject={optionsObjTable} valueProperty={type.idProperty ? type.idProperty : key} />
                            </div>
                        )
                    } else {
                        completeCompetenceAfterUpdate((v) => setValues({...values, [key]: v}), values[key], competenceData, type.dataAttribute, ['titel', 'name'], type.idProperty)
                        return <TableInput key={index} presentation={presentation} name={key} readOnly={checkReadOnly(key, readOnly, accessStatus)} disabledObject={{titel: true}} presentationObject={businessobject_data[type.dataAttribute].zoFields} value={values[key]} setValue={v => setValues({...values, [key]: v})} options={dropdownOptions} optionsObject={optionsObjTable} valueProperty={type.idProperty ? type.idProperty : key} />
                    }
                case data.inputTypes.advanced.OBJECTFK:
                    function getList4ObjectFK(){
                        switch(type.businessObject){
                            case data.businessObject.customerproject:
                                return fkLists.customerprojects
                            case data.businessObject.contactperson:
                                const fkKundeId = (fkLists && fkLists.customerprojects && fkLists.customerprojects.find(obj => obj.id === values.kundenprojektId) ? fkLists.customerprojects.find(obj => obj.id === values.kundenprojektId).kundeId  : null)
                                return (fkKundeId && optionsFilter(fkLists.customers, 'id', fkKundeId)[0] ? optionsFilter(fkLists.customers, 'id', fkKundeId)[0].ansprechpartner : undefined)
                            case data.businessObject.consultant:
                                return fkLists.consultants
                            default:
                                return []
                        }
                    }
                    return (
                        <ObjectFKInput key={index} name={key} readOnly={((accessStatus === data.accessStatus.create && role === data.roles.MITARBEITER && type.businessObject === data.businessObject.consultant)? true :checkReadOnly(key, readOnly, accessStatus))} presentation={presentation} value={values[key]} setValue={v => setValues({...values, [key]: v})} setOverwrite={(type.overwrite ? (id, o) => {let tempValues = {...values}; type.overwrite.map((k, i) => {tempValues[k] = o[k]}); tempValues[key]=id; setValues(tempValues)} : null)} multiSelect={type.multiSelect} create={type.create} objectList={getList4ObjectFK()} businessObject={type.businessObject} valueProperty={type.selectProperty} presentationProperty={(type.presentationProperty ? type.presentationProperty : type.selectProperty)} filter={((type.filterBy && type.filterValue) ? {attr: type.filterBy, value: type.filterValue} : null)} isInvalid={checkInvalidation(key, failedValidation, setFailedValidation, values[key], (businessobject_data.types[key]?.semanticCheck?.compareTo ? values[businessobject_data.types[key].semanticCheck.compareTo] : null))} notNull={(key === 'kundenprojektId' ? (values['projekttyp']==='VOR_EINSTELLUNG' ? false : true) : ((typeof type.notnull === 'boolean' && type.notnull) || (Array.isArray(type.notnull) && type.notnull.includes(businessObject))))}  />
                    )
                default:
                    return <InputByType key={index} passKey={index} type={type.type} asNumber={type.asNumber} readOnly={((['kurzBeschreibung', 'langBeschreibung'].includes(key) && values['kundenprojektId'])? true : checkReadOnly(key, readOnly, accessStatus))} maxChar={type.maxChar} value={values[key]} name={key} presentation={presentation} setValue={v => setValues({...values, [key]: v})} isInvalid={checkInvalidation(key, failedValidation, setFailedValidation, values[key], (businessobject_data.types[key]?.semanticCheck?.compareTo ? values[businessobject_data.types[key].semanticCheck.compareTo] : null))} notNull={((typeof type.notnull === 'boolean' && type.notnull) || (Array.isArray(type.notnull) && type.notnull.includes(businessObject)))}  />
            }
        } else {
            return <TextInput key={index} readOnly={checkReadOnly(key, readOnly, accessStatus)} value={values[key]} name={key} presentation={presentation} setValue={v => setValues({...values, [key]: v})} isInvalid={checkInvalidation(key, failedValidation, setFailedValidation, values[key], (businessobject_data.types[key]?.semanticCheck?.compareTo ? values[businessobject_data.types[key].semanticCheck.compareTo] : null))} notNull={(businessobject_data.types[key] && ((typeof businessobject_data.types[key].notnull === 'boolean' && businessobject_data.types[key].notnull) || (Array.isArray(businessobject_data.types[key].notnull) && businessobject_data.types[key].notnull.includes(businessObject))))}  />
        }
    }


    if (!values || Object.keys(values).length === 0 && values.constructor === Object){
        return (
            <div className="content" id="consultantproject">
                <PflegenKopfzeile_Beraterprojekt accessStatus={accessStatus} readOnly={true} businessObjectFunctions={{onDelete, onLock, onSave, onCreate, onCreateLock, onRelease}} setTab={setTab} tabs={tabs} tab={tab} />
                <div className="inner-content">
                    {errorMsg && <div id="server-error"> <br /> <strong className="error">{errorMsg}</strong></div>}
                    <div className="not-found">
                        <div>Das Beraterprojekt <b>{id}</b> wurde nicht gefunden.</div>
                    </div>
                </div>
                <PflegenFusszeile accessStatus={accessStatus} modDate={'00.00.0000 00:00'} />
            </div>
        )
    } else {
        return (
            <div className="content" id="consultantproject">
            <PflegenKopfzeile_Beraterprojekt role={role} id={id} loggedInUsername={username} fkLists={fkLists} accessStatus={accessStatus} object={values} readOnly={readOnly} somethingChanges={somethingChanges(accessStatus, values, object, businessObject)} businessObjectFunctions={{onDelete, onLock, onSave, onCreate, onCreateLock, onRelease, onSavePM, onSavePMPrivate, onCreatePM, onCreatePMPrivate}} setTab={setTab} tabs={tabs} tab={tab} setWantedStatus={setWantedStatus} objectList={object}/>
            <div className="inner-content">
                {(fieldDataError && !errorMsg) && <div id="server-error"><strong className="error">{fieldDataError}</strong><br /> <br /> </div>}
                {errorMsg && <div><strong className="error">{errorMsg}</strong></div>}
                {showDeletePopUp ? <PopUp title='Sind Sie sich sicher?' text={<div>Sie möchten das Beraterprojekt, <strong>{id}</strong> löschen</div>} close={onDelete_sure} abort={() => setShowDeletePopUp(false)} closeText="Löschen" /> : null}
                {showLockPopUp ? <PopUp title='Sind Sie sich sicher?' text={<div>Sie möchten das Beraterprojekt, <strong>{id}</strong> {(values.status === data.status.GESPERRT ? "Entsperren" : "Sperren")}</div>} close={onLock_sure} abort={() => setShowLockPopUp(false)} closeText={(values.status === data.status.GESPERRT ? "Entsperren" : "Sperren")} /> : null}
                {accessStatus === data.accessStatus.create && <h3>Neues Beraterprojekt anlegen</h3>}
                {error && <div id="client-error"> <br /> <small className="error">{error}</small></div>}
                <HandleMainInputs fields={fields} tab={tab} businessObject={businessObject} returnInput={returnInput} />
            </div>
            <PflegenFusszeile accessStatus={accessStatus} modDate={(object ? object.modDate : '00.00.0000, 00:00')} />
            </div>
        )
    }
}

/**
 * Weist den verwendeten lokalen Variablen, variablen aus dem State zu
 * 
 * @param {*} state - aktueller State
 * @param {*} ownProps - weitere Variablen
 * @returns den aktuellen State als Komponentenvariablen
 */
 const mapStateToProps = (state, ownProps) => ({
    role: state.user.role,
    username: state.user.username,
    object: state.consultantprojects.specific,
    createdId: state.consultantprojects.createdId,
    list: state.consultantprojects.list,
    fkLists: {consultants: state.consultants.list, customers: state.customers.list, customerprojects: state.customerprojects.list},
    errorMsg: state.businessobjects.error,
    successMsg: state.businessobjects.success,
    fieldData: state.fielddata.data,
    fieldDataError: state.fielddata.error,
    competenceData: state.competences.list,
    requestedFile4Download: state.consultantprojects.requestedFile,
    pmCompetenceSuccess: state.competences.competencePMCompetenceRequest,
    ...ownProps
})

/**
 * Weist den verwendeten lokalen Funktionen, Funktionen aus dem Redux zu
 * 
 * @returns Die gemappten Funktionen
 */
const mapDispatchToProps = dispatch => ({
    get: (id) => dispatch(sendGetConsultantprojectRequest(id)),
    sendDelete: (id) => dispatch(sendDeleteConsultantprojectRequest(id)),
    sendUpdate: (consultantproject) => dispatch(sendUpdateConsultantprojectRequest(consultantproject)),
    sendCreate: (consultantproject) => dispatch(sendCreateConsultantprojectRequest(consultantproject)),
    sendCompetenceCreateRequest: (competence) => dispatch(sendCreatePMCompetenceRequest(competence)),
    sendCreateFile: (id, file) => dispatch(sendCreateFileRequest(id, file)),
    sendDeleteFile: (id, fileId) => dispatch(sendDeleteConsultantprojectFileRequest(id, fileId)),
    sendFileDownload: (id, fileId) => dispatch(sendFileDownload(id, fileId, false)),
    setfurtherSuccess: () => dispatch(setSuccess())
}) 

export default connect(mapStateToProps, mapDispatchToProps)(Pflegen_Beraterprojekt)