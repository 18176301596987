import React, {useState, useEffect} from 'react'
import {useHistory} from 'react-router-dom'
import {MultiSelect} from 'react-multi-select-component'

import SearchListElement from './SearchListElement'
import {PermissionForbidden} from './ReadPermission'

import data from '../../data/_data'
import businessobject_data from '../../data/businessobject_data'
import {equals, object2options, sortList} from './functions'
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd'
import message_data from '../../data/message_data'

/**
 * Rendert zu den übergebenden Daten eine Liste der Objekte, Möglichkeit zum Filter, Durchsuchen und Sortieren
 *
 * @author DHR
 * @param {String} geschaeftsobjekt - aktuelles Geschäftsobjekt der Route
 * @param {Object} list - JSON Objekt der anzuzegenden Liste (möglich sind alle Geschäftsobjekte zu denen data.attributes definiert sind)
 * @param {boolean} locked - Filterparameter "Gesperrt"
 * @param {String} roleFilter - Filterparameter der Berechtigungsrolle
 * @param {String} searchAttr - Suchparameter: Attribut
 * @param {String} searchVal - Suchparameter: Wert
 * @returns {HTML} Listen-Übersicht mit Tabellenkopf und Listenelementen
 */
const SearchList = ({role, username, businessObject, list = [], settings, dragable, orderDragNDrop, setCountList, locked, roleFilter, searchAttr, searchVal, searchAttr2, searchVal2, dateFilter, statusFilter, booleanFilter, categoryFilter, onSelect, excludeFromSelect, onSelectProperty, onSelectPrefiltered, onSelectCreate, onSelectEdit, decode2presentation, abwaehlen_statt_auswaehlen = false}) => {
    // if (searchAttr === ""){searchAttr=Object.keys((!categoryFilter ? (onSelectPrefiltered ? onSelectPrefiltered.extendOptions : businessobject_data[businessObject].searchAttributes) : (businessobject_data.types[categoryFilter] && businessobject_data.types[categoryFilter].attributeGroup ? businessobject_data[businessobject_data.types[categoryFilter].attributeGroup].searchAttributes : businessobject_data[categoryFilter].searchAttributes) ))[0]}
    let history = useHistory()
    // Die möglichen Spalten
    var extendOptions = object2options(onSelectPrefiltered ? onSelectPrefiltered.extendOptions : categoryFilter ? (businessobject_data.types[categoryFilter] && businessobject_data.types[categoryFilter].attributeGroup ? businessobject_data[businessobject_data.types[categoryFilter].attributeGroup].searchAttributes : businessobject_data[categoryFilter].searchAttributes) : businessobject_data[businessObject]?.searchAttributes)
    if (businessObject === data.businessObject.moderation) {
        if (categoryFilter === data.businessObject.competence || categoryFilter === data.businessObject.qualification) {
            extendOptions.push({value: 'modBy', label: 'Geändert von'})
        }
    }
    if (searchAttr === '') {
        searchAttr = extendOptions[0] ? extendOptions[0].value : ''
    }
    const [selectedColumns, setSelectedColumns] = useState(extendOptions.slice(0, 5))
    const overrideStrings = {
        allItemsAreSelected: ' + ',
        clearSearch: '',
        noOptions: '',
        search: '',
        selectAll: '',
        selectSomeItems: ' + ',
    }

    //legt die Spalte fest nach welcher sortiert werden soll
    const [sort, setSort] = useState(selectedColumns[0])

    //legt fest ob auf -oder absteigend sortiert werden soll
    const [sortASC, setSortASC] = useState(true)

    //setzt die zu sortierende Spalte
    useEffect(() => {
        setSelectedColumns(extendOptions.slice(0, 5))
        setSort(extendOptions[0])
    }, [businessObject, categoryFilter])

    // columnCount berechnung insb. für SearchListElement
    var columnCount = selectedColumns.length + 2
    if (onSelectPrefiltered) {
        settings = onSelectPrefiltered.settings
    }
    if (settings[businessObject].extendStyle.display === 'none') {
        columnCount = columnCount - 1
    }
    const gridStyle = {gridTemplateColumns: (dragable ? '50px ' : '') + 'repeat(' + columnCount + ', auto)'}
    
    const filter = () => {
        let token1 = searchVal?.replace(/^\s+|\s+$/g, "").replace(/[.?*+^$[\]\\(){}|-]/g, "\\$&")
        let re1 = new RegExp("(^|\\s|[-.\\(_])" + token1 + "", "i");
        let token2 = searchVal2?.replace(/^\s+|\s+$/g, "").replace(/[.?*+^$[\]\\(){}|-]/g, "\\$&")
        let re2 = new RegExp("(^|\\s|[-.\\(_])" + token2 + "", "i");

        const tempFiltered = []
        for (let elem of list) {
            if (
                elem &&
                (searchVal ? ((!businessobject_data.displayList[searchAttr] && (searchAttr.includes('Id') || typeof elem[searchAttr] === 'object')) ? re1.test(decode2presentation(searchAttr, elem[searchAttr], elem)) : businessobject_data.displayList[searchAttr] ? re1.test(decode2presentation(searchAttr, elem)) : elem[searchAttr] ? re1.test(elem[searchAttr].toLowerCase()) : false) : true) &&
                (searchVal2 ? (businessobject_data.displayList[searchAttr2] ? (typeof elem[searchAttr2] === 'number' ? decode2presentation(searchAttr2, elem) >= parseInt(searchVal2) : re2.test(decode2presentation(searchAttr2, elem))) : elem[searchAttr2] ? (typeof elem[searchAttr2] === 'number' ? elem[searchAttr2] >= parseInt(searchVal2) : re2.test(elem[searchAttr2].toLowerCase())) : false) : true) &&
                (roleFilter ? elem.rolle === roleFilter : true) &&
                (dateFilter && (!!dateFilter.searchStartDate || !!dateFilter.searchEndDate) ? (dateFilter.searchEndDate === -1 ? !elem.enddatum || elem.enddatum === null || elem.enddatum === undefined : !!dateFilter.searchStartDate && !!dateFilter.searchEndDate ? elem.startdatum >= dateFilter.searchStartDate && elem.enddatum <= dateFilter.searchEndDate : !!dateFilter.searchStartDate ? elem.startdatum >= dateFilter.searchStartDate : elem.enddatum <= dateFilter.searchEndDate) : true)
            ) {
                let passedStatus = false
                let passedBoolean = []

                if (statusFilter && Object.keys(statusFilter).length > 0 && elem.status) {
                    for (let status of Object.keys(statusFilter)) {
                        if (elem.status === status) {
                            passedStatus = true
                            break
                        }
                    }
                } else {
                    passedStatus = true
                }
                if (booleanFilter && Object.keys(booleanFilter).length > 0) {
                    for (let bool of Object.keys(booleanFilter)) {
                        if (elem[bool]) {
                            passedBoolean.push(true)
                        }
                    }
                } else {
                    passedBoolean = booleanFilter ? [...Object.keys(booleanFilter)] : []
                }
                if (passedStatus && (!(booleanFilter && Object.keys(booleanFilter).length > 0) || passedBoolean.length === Object.keys(booleanFilter).length)) {
                    tempFiltered.push(elem)
                }
            }
        }
        if (dragable) {
            return tempFiltered
        }
        return sortList(sort.value, sortASC, tempFiltered, decode2presentation) //.sort(function(a,b){return ((a[sort.value]&&b[sort.value]) ? (typeof a[sort.value] === 'string' ? (a[sort.value].toLowerCase().localeCompare(b[sort.value].toLowerCase()) === 0 ? ((a.titel && b.titel) ? a.titel.toLowerCase().localeCompare(b.titel.toLowerCase()) : 0) : a[sort.value].toLowerCase().localeCompare(b[sort.value].toLowerCase())) : (typeof a[sort.value] === 'number' ? a[sort.value] <= b[sort.value] : false)):false)})
    }

    

    // IDs der Daten zur Anzeige in Array
    const [filteredList, setFilteredList] = useState(filter())

    useEffect(() => {
        if (setCountList) {
            setCountList(filteredList.length)
        }
    }, [filteredList])
    useEffect(() => {
        setFilteredList(filter())
    }, [businessObject, onSelectPrefiltered, searchVal, searchVal2, locked, roleFilter, searchAttr, searchAttr2, statusFilter, booleanFilter, dateFilter, categoryFilter]) //list
    useEffect(() => {
        if (list && (list.length != filteredList.length) && (businessObject === data.businessObject.user || businessObject === data.businessObject.layout)) {
            setFilteredList(filter())
        }
    }, [list])

    // sortiere das anzuzeigende Array
    useEffect(() => {
        setFilteredList(sortList(sort.value, sortASC, filteredList, decode2presentation))
    }, [sort, sortASC, businessObject])

    const elem2Data = (elem) => {
        var listData = []
        for (let attr of selectedColumns) {
            if (attr.value === 'rolle') {
                listData.push(data.presentation.roles[elem[attr.value]])
            } else if (attr.value === 'status') {
                listData.push(!!(elem[attr.value] === data.status.GESPERRT))
            } else if (decode2presentation && !businessobject_data.displayList[attr.value] && (attr.value.includes('Id') || (typeof elem[attr.value] === 'string' && (elem[attr.value].toUpperCase() === elem[attr.value] || elem[attr.value].includes('__') || elem[attr.value].includes('_u_'))) || typeof elem[attr.value] === 'object')) {
                listData.push(decode2presentation(attr.value, elem[attr.value], elem))
            } else if (decode2presentation && businessobject_data.displayList[attr.value]) {
                listData.push(decode2presentation(attr.value, elem))
            } else if (attr.value === 'attributeType' && elem[attr.value]) {
                listData.push(elem[attr.value][0].toUpperCase() + elem[attr.value].substring(1))
            } else {
                listData.push(elem[attr.value])
            }
        }
        return listData
    }

    const onNew = (g) => {
        if (!!onSelectCreate) {
            onSelectCreate()
        } else {
            switch (g) {
                case 'benutzer':
                    history.push('/administration/benutzer/neu')
                    break
                case 'layout':
                    history.push('/administration/layout/neu')
                    break
                default:
                    history.push('/pflegen/' + g + '/neu')
                    break
            }
        }
    }

    const onEdit = (id) => {
        if (excludeFromSelect && ((Object.keys(excludeFromSelect) && Object.keys(excludeFromSelect).includes(id)) || Array.isArray(excludeFromSelect) && excludeFromSelect.includes(id))) {
            return
        }
        switch (businessObject) {
            case data.businessObject.user:
                if (onSelect) {
                    onSelect(id)
                    break
                } else {
                    history.push('/administration/benutzer/' + id)
                    break
                }
            case data.businessObject.moderation:
                let prefix = id.split('_')[0] + '_'
                let id2 = id.split('_')[1]
                if (prefix === data.prefixes.consultant) {
                    history.push('/pflegen/berater/' + id2)
                    break
                }
                if (prefix === data.prefixes.consultantproject) {
                    history.push('/pflegen/beraterprojekt/' + id2)
                    break
                }
                if (prefix === data.prefixes.competence) {
                    history.push('/pflegen/kompetenz/' + id2)
                    break
                }
                break
            case data.businessObject.drucken:
                history.push('/drucken/' + id)
                break
            case data.businessObject.competence:
                if (onSelect) {
                    onSelect(id)
                    break
                }
                history.push('/pflegen/' + businessObject + '/' + (businessobject_data.types[categoryFilter] && businessobject_data.types[categoryFilter].attributeGroupParent ? id : categoryFilter + '/' + id))
                break
            case data.businessObject.layout:
                history.push('/administration/layout/' + id)
                break
            default:
                if (onSelect) {
                    onSelect(id)
                    break
                } else {
                    history.push('/pflegen/' + businessObject + '/' + id)
                }
        }
    }
    const onEditLook = (id) => {
        switch (businessObject) {
            case data.businessObject.user:
                history.push('/administration/benutzer/' + id + '?readOnly=true')
                break
            case data.businessObject.moderation:
                let prefix = id.split('_')[0] + '_'
                let id2 = id.split('_')[1]
                if (prefix === data.prefixes.consultant) {
                    history.push('/pflegen/berater/' + id2 + '?readOnly=true')
                    break
                }
                if (prefix === data.prefixes.consultantproject) {
                    history.push('/pflegen/beraterprojekt/' + id2 + '?readOnly=true')
                    break
                }
                if (prefix === data.prefixes.competence) {
                    history.push('/pflegen/kompetenz/' + id2 + '?readOnly=true')
                    break
                }
            case data.businessObject.competence:
                if (onSelect) {
                    onSelect(id)
                    break
                }
                history.push('/pflegen/' + businessObject + '/' + (businessobject_data.types[categoryFilter] && businessobject_data.types[categoryFilter].attributeGroupParent ? id : categoryFilter + '/' + id) + '?readOnly=true')
                break
            case data.businessObject.layout:
                history.push('/administration/layout/' + id + '?readOnly=true')
                break
            default:
                history.push('/pflegen/' + businessObject + '/' + id + '?readOnly=true')
        }
    }

    const ObjectID = (index, elem) => {
        if (onSelect) {
            if (onSelectProperty === 'INDEX') {
                return index
            } else {
                return elem[onSelectProperty]
            }
        } else {
            switch (businessObject) {
                case data.businessObject.moderation:
                    if (elem.kuerzel) {
                        return data.prefixes.consultant + elem.id
                    }
                    if (elem.berater) {
                        return data.prefixes.consultantproject + elem.id
                    }
                    if (elem.kategorie || elem.typ) {
                        return data.prefixes.competence + (categoryFilter ? categoryFilter : elem.typ ? data.businessObject.qualification : data.businessObject.competence) + '/' + elem.id
                    }
                    return elem.id
                case data.businessObject.user:
                    return elem.benutzername
                case data.businessObject.drucken:
                case data.businessObject.consultant:
                    return elem.kuerzel
                case data.businessObject.competence:
                    return (businessobject_data.types[categoryFilter] && businessobject_data.types[categoryFilter].attributeGroupParent ? elem.attributeType + '/' : '') + elem.id
                default:
                    return elem.id
            }
        }
    }

    const searchListElementsComponent = () => {
        if (dragable) {
            return (
                <DragDropContext onDragEnd={orderDragNDrop}>
                    <Droppable droppableId="searchlist-dragNdrop">
                        {(provided) => (
                            <div className="searchlist-body searchlist-dragNdrop" {...provided.droppableProps} ref={provided.innerRef} key={businessObject + '_sort-' + sort?.value + '-' + sortASC}>
                                {filteredList.map((elem, index) => (
                                    <Draggable key={elem.id.toString()} draggableId={elem.id.toString()} index={index}>
                                        {(provided) => <SearchListElement key={10000 + index + '' + elem.id + '_sort-' + sort?.value + '-' + sortASC} passKey={1000 + elem.id} provided={provided} dragable={dragable} readOnly={PermissionForbidden(role, username, ObjectID(index, elem), businessObject)} columnCount={columnCount} id={ObjectID(index, elem)} rowData={elem2Data(elem)} onEdit={onEdit} onEditLook={onEditLook} onSelect={!!onSelect || businessObject === data.businessObject.drucken} abwaehlen_statt_auswaehlen={abwaehlen_statt_auswaehlen} businessObject={businessObject}/>}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            )
        }
        return (
            <div className="searchlist-body" key={businessObject + '_sort-' + sort?.value + '-' + sortASC}>
                {filteredList.map((elem, index) => (
                    <SearchListElement
                        key={10000 + index + '' + elem.id + '_sort-' + sort?.value + '-' + sortASC}
                        passKey={1000 + elem.id}
                        sort={sort}
                        sortASC={sortASC}
                        dragable={dragable}
                        readOnly={PermissionForbidden(role, username, ObjectID(index, elem), businessObject, ((businessObject === data.businessObject.consultantproject && elem.berater === username) ? elem : {}))}
                        columnCount={columnCount}
                        id={ObjectID(index, elem)}
                        rowData={elem2Data(elem)}
                        secondaryStyle={elem.status ? elem.status === data.status.GESPERRT : null}
                        onEdit={onEdit}
                        onEditLook={onEditLook}
                        onSelect={!!onSelect || businessObject === data.businessObject.drucken}
                        excludeFromSelect={(excludeFromSelect ? (!Array.isArray(excludeFromSelect) && Object.keys(excludeFromSelect).includes(ObjectID(index, elem).toString()) ? excludeFromSelect[ObjectID(index, elem)] : (Array.isArray(excludeFromSelect) ? excludeFromSelect.includes(ObjectID(index, elem).toString()) : false)): false )}
                        abwaehlen_statt_auswaehlen={abwaehlen_statt_auswaehlen}
                        businessObject={businessObject}
                    />
                ))}
            </div>
        )
    }


    return (
        <div className="searchlist" /*style={gridStyle}*/>
            <div className="searchlist-header">
                <div className="searchlist-element">
                    {dragable && <div className="searchlist-header-item" id="dragable"></div>}
                    {selectedColumns.map((column, index) => (
                        <div
                            key={index}
                            className="searchlist-header-item searchlist-header-sort-column"
                            onClick={(e) => {
                                setSort(column)
                                setSortASC(!sortASC)
                            }}>
                            {column.label}
                            {sort === column && sortASC ? <i className="arrow down" /> : sort === column && !sortASC ? <i className="arrow up" /> : null}
                        </div>
                    ))}
                    <div className="searchlist-header-item" id="new-button">
                        {Array.isArray(settings[businessObject].createButtonValue) ? (
                            settings[businessObject].createButtonValue.map((value, index) => (
                                <button className="small" key={index} disabled={PermissionForbidden(role, username, 'neu', businessObject) | !!onSelect} tooltip={PermissionForbidden(role, username, 'neu', businessObject) ? message_data.tooltip.missing_auth : ''} onClick={() => onNew(value.value)}>
                                    {value.label}
                                </button>
                            ))
                        ) : !settings[businessObject].createDisabled ? (
                            <button className="small" disabled={PermissionForbidden(role, username, 'neu', businessObject) | (!!onSelect && !!!onSelectCreate)} tooltip={PermissionForbidden(role, username, 'neu', businessObject) ? message_data.tooltip.missing_auth : ''} onClick={() => onNew(businessObject)}>
                                {settings[businessObject].createButtonValue}
                            </button>
                        ) : null}
                    </div>
                    <div className="searchlist-header-item" id="extend-button" style={settings[businessObject].extendStyle}>
                        <MultiSelect
                            className="searchlist-extend"
                            options={extendOptions}
                            value={selectedColumns}
                            valueRenderer={(selected, _options) => {
                                return ' + '
                            }}
                            onChange={setSelectedColumns}
                            overrideStrings={overrideStrings}
                            disableSearch
                            hasSelectAll
                        />
                    </div>
                </div>
            </div>
            {searchListElementsComponent()}
        </div>
    )
}

export default SearchList
